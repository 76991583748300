export interface CarouselSliderOptions {
	container: HTMLElement
	duration: number
	total: number
	update: (current: number) => void
}

export class CarouselSlider {
	#opts

	#currentIndex = 0

	get currentIndex() {
		return this.#currentIndex
	}

	set currentIndex(index: number) {
		const safeIndex = Math.max(0, Math.min(index, this.#opts.total - 1))

		if (safeIndex === this.#currentIndex) {
			return
		}

		this.#currentIndex = safeIndex
		this.#update()
	}

	#timeoutId: number | undefined

	constructor(options: CarouselSliderOptions) {
		this.#opts = options

		if (window.IntersectionObserver) {
			const observer = new IntersectionObserver(([entry]) => {
				if (entry.isIntersecting) {
					this.play()
				} else {
					this.pause()
				}
			})

			observer.observe(this.#opts.container)
		} else {
			this.play()
		}
	}

	#clearTimeout() {
		window.clearTimeout(this.#timeoutId)
	}

	#schedule() {
		this.#clearTimeout()

		this.#timeoutId = window.setTimeout(() => {
			this.goNext()
		}, this.#opts.duration)
	}

	pause() {
		this.#clearTimeout()
	}

	play() {
		this.#schedule()
	}

	#update() {
		this.#schedule()

		this.#opts.update(this.currentIndex)
	}

	goNext() {
		if (this.currentIndex >= this.#opts.total - 1) {
			this.currentIndex = 0
		} else {
			this.currentIndex += 1
		}
	}

	goPrev() {
		if (this.currentIndex === 0) {
			this.currentIndex = this.#opts.total - 1
		} else {
			this.currentIndex -= 1
		}
	}

	goTo(index: number) {
		this.currentIndex = index
	}
}
